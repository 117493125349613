import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Helmet>
      <title>
        Ensure Email Deliverability: Check DMARC, DKIM, SPF Records & Blacklists
        for Cold Emails.
      </title>
      <meta
        name="description"
        content="Verify the authenticity and improve the deliverability of your cold emails with our DMARC, DKIM, SPF Record Checker. Safeguard your email reputation and maximize outreach success by ensuring proper email authentication protocols. Try our tool now!"
      />
      <meta
        name="keywords"
        content="dmarc, dmarc check, dmarc checker, check dmarc, dmarc record, dmarc records, what is dmarc, dmarc policy, dmarc policies, dmarc generator, dmarc lookup, lookup dmarc, dmarc creator, dmarc dkim and spf, check dmarc records, test dmarc records, spf dkim dmarc, check dmarc record, dmarc spf dkim, dmarc test, dmarc record checker, dmarc record test, verify dmarc record, dmarc record tester, spf dkim and dmarc, dkim/spf dmarc, dmarc tester, dmarc record check, test dmarc record, dmarc dkim spf, dmarc record generator, dkim dmarc spf, dmarc testing, dkim dmarc and spf, dkim spf dmarc, check for dmarc record, generate dmarc record, dmarc records check, test dmarc, dmarc meaning, dmarc emails, email dmarc, dmarc for email, dmarc mail, dmarc analyzer, dmarc email, dmarc failed, dmarc failure, dmarc fail, create dmarc record, create a dmarc record, dmarc report analyser, dmarc reporting, dmarc report, dmarc report analyzer, dmarc google, what is a dmarc record, what are dmarc records, google dmarc, what is dmarc record, dmarc and gmail, mxtoolbox dmarc, gmail dmarc, setup dmarc, set up dmarc, dmarc gmail, dmarc quarantine/reject policy not enabled, dmarc food pantry, how to setup dmarc, how to set up dmarc, dmarc and dkim, dmarc quarantine reject policy not enabled, easy dmarc, dkim and dmarc, dmarc for gmail, dmarc room, dmarc food pantry des moines, spf dkim/dmarc check, what is dmarc in email, dmarc authentication, check spf dkim dmarc, dmarc record not found, no dmarc record found, dmarc what is, dmarc verification, dmarc record examples, dmarc dkim spf check, spf dmarc dkim check, adding a dmarc record, dmarc policy not enabled, example dmarc record, add dmarc record, spf dkim dmarc test, dmarc box, spf dkim dmarc checker, test spf dkim dmarc, dmarc record example, verify dmarc, dkim spf dmarc checker, spf dkim dmarc check"
      />
    </Helmet>
    <App />
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
