import { LandingPage } from "./Pages/LandingPage/inedx";
import { BackgroundStyling } from "./Pages/LandingPage/BackgroundStyling";
import { FunctionalPage } from "./Pages/FunctionalPage";
import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [domain, setDomain] = useState(null);
  useEffect(() => {
    var url = window.location.href;
    var domainValue = getParameterByName("domain", url);
    if (domainValue) {
      setDomain(domainValue);
    }
  }, []);
  return (
    <div className="relative overflow-hidden h-screen">
      {domain ? <FunctionalPage /> : <LandingPage />}
      <BackgroundStyling />
    </div>
  );
}

export default App;

function getParameterByName(name, url) {
  name = name.replace(/[[\]]/g, "$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
