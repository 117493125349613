import React, { useState } from "react";
import {
  ArrowIcon,
  FacebookIcon,
  FireIcon,
  NumailLogo,
  XIcon,
  YoutubeIcon,
} from "../../components/icons";
import { Avatar } from "../../components/Avatar";
import Avatar1 from "../../img/Avatar1.png";
import Avatar2 from "../../img/Avatar2.png";
import Avatar3 from "../../img/Avatar3.png";
import { Popover } from "antd";

export const LandingPage = () => {
  const [input, setInput] = useState(null);

  const handleCheck = () => {
    if (input !== "" && input) {
      window.location.href = "?domain=" + input;
    }
  };

  return (
    <div className="flex flex-col items-center mt-12">
      <NumailLogo />
      <div className="flex gap-2 items-center mt-8">
        <FireIcon />
        <p className="text-[#69696A] text-[20px]">
          DMARC, DKIM, & SPF Record Checker
        </p>
      </div>
      <div className="flex justify-center">
        <p
          className="text-[64px] text-white w-[70%] text-center font-extrabold"
          style={{
            background: "linear-gradient(to right, #FFFFFF, #7E99D0)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          DMARC, DKIM, & SPF Checker
        </p>
      </div>
      <div className="mt-4">
        <p className="text-[16px] text-white">
          Lookup your DMARC, DKIM, & SPF records to identify any problems and
          fix them.
        </p>
        <div className="mt-8 flex gap-4">
          <input
            className="bg-transparent border-[#4F4F4F] border-[1px] rounded-lg w-[65%] px-2 py-1 outline-none text-white"
            placeholder="Example.com"
            onChange={(ev) => setInput(ev.target.value)}
          />
          <button
            className="text-white border-[1px] border-[#4F5054] w-[35%] rounded-lg flex gap-2 items-center justify-center"
            style={{
              background: "linear-gradient(to right, #1E293F, #0F0F0F)",
            }}
            onClick={() => handleCheck()}
          >
            <span className="text-[20px]">Check Records</span>
            <ArrowIcon />
          </button>
        </div>
      </div>
      <div className="mt-8 flex gap-4 items-center">
        <div className="flex">
          <Avatar isMargin={false} img={Avatar1} />
          <Avatar isMargin={true} img={Avatar2} />
          <Avatar isMargin={true} img={Avatar3} />
        </div>

        <p className="text-[16px] text-[#6B7280]">
          Join 20,000+ others who signed up
        </p>
      </div>
      <div className="flex items-center mt-4 gap-3">
        <FacebookIcon />
        <YoutubeIcon />
        <XIcon />
      </div>

      <div className="flex gap-4 mb-[5rem] mt-8">
        <MicroservicesComponent
          header={"Email Template Library"}
          text={`Cold Email Library is a curated list of cold email templates for a wide range of use cases & niches. Over 2,000 templates for FREE.`}
        />
        <MicroservicesComponent
          header={"Cold Email Mafia"}
          text={`Cold Email Mafia is a private community for cold emailers. To qualify to join,
           you need to be sending at least 10,000 emails a month and willing to share your journey with the rest.
                 We also have cool badges & rankings for our Mafia Bosses.`}
        />
        <MicroservicesComponent
          header={"DMARC, DKIM, SPF & Reputation Checker"}
          text={
            "We trained an AI model on over 200,000 cold emails with over 10,000 different subject lines. Give us the right info and we’ll generate 10 cold email subject lines that are guaranteed to outperform “Quick Question?”"
          }
        />
      </div>
    </div>
  );
};

const MicroservicesComponent = ({ header, text }) => {
  const content = (
    <div className="w-[14.5rem]">
      <p className="text-white text-center">{text}</p>
    </div>
  );

  return (
    <Popover content={content}>
      <div className="bg-[#021336] py-4 px-8 flex gap-2 items-center rounded-md w-[17rem] justify-center">
        <p
          style={{
            background: "linear-gradient(to right, #FFFFFF, #888888)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          {header}
        </p>
        <div className="mt-1">
          <ArrowIcon />
        </div>
      </div>
    </Popover>
  );
};