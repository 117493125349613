import React, { useEffect, useState } from "react";
import {
  ArrowIcon,
  CheckIcon,
  NumailLogo,
  ValidIcon,
} from "../../components/icons";
import { Spin } from "antd";

export const FunctionalPage = () => {
  const [domain, setDomain] = useState("");
  const [result, setResult] = useState({
    MXChanges: [],
    SPFRecords: [],
    DMARCRecords: [],
    DKIMRecords: [],
    Blacklist: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    var url = window.location.href;
    var domainValue = getParameterByName("domain", url);
    if (domainValue) {
      setDomain(domainValue);
      handleCheck(domainValue);
    }
  }, []);

  const handleCheck = async (domain) => {
    setIsLoading(true);

    if (domain === "" || !domain) {
      window.alert("Please enter a valid domain");
    } else {
      try {
        fetch("https://generator.cyclic.app/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message: domain }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setIsLoading(false);
            setResult(data);
          })
          .catch((error) => {
            console.error(
              "There was a problem with your fetch  operation:",
              error
            );
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center mt-12">
      {isLoading ? <Spin fullscreen /> : <></>}
      <NumailLogo />
      <div className="flex gap-4 mt-8 w-full px-[30rem]">
        <input
          className="bg-transparent border-[#4F4F4F] border-[1px] rounded-lg w-[65%] px-2 py-1 outline-none text-white"
          placeholder="Example.com"
          value={domain}
          onChange={(ev) => setDomain(ev.target.value)}
        />
        <button
          className="text-white border-[1px] border-[#4F5054] w-[35%] rounded-lg flex gap-2 items-center justify-center"
          style={{
            background: "linear-gradient(to right, #1E293F, #0F0F0F)",
          }}
          onClick={() => handleCheck(domain)}
        >
          <span className="text-[20px]">Check Records</span>
          <ArrowIcon />
        </button>
      </div>
      <div className="flex gap-12 justify-between mt-12 w-full px-[15rem]">
        <LeftComponent data={result} />
        <RightComponent data={result?.Blacklist} />
      </div>
    </div>
  );
};

const LeftComponent = ({ data }) => {
  return (
    <div className="flex flex-col gap-4 w-full h-[28.5rem]">
      <div
        className="rounded-[20px] p-4"
        style={{
          background: "linear-gradient(to right, #620F6C, #2C4DCA)",
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span className="text-white">DMARC Record</span>
          </div>
          <Status value={data?.DMARCRecords} />
        </div>

        <div className="mt-4">
          <p className="text-white text-[0.9rem]">
            {data?.DMARCRecords || "DMARC Records not found"}
          </p>
        </div>
      </div>

      <div
        className="rounded-[20px] p-4"
        style={{
          background: "linear-gradient(to right, #620F6C, #2C4DCA)",
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span className="text-white">DKIM Record</span>
          </div>
          <Status value={data?.DKIMRecords} />
        </div>

        <div className="mt-4">
          <p className="text-white text-[0.9rem]">
            {data.DKIMRecords[0] || "DKIM Records not found"}
          </p>
        </div>
      </div>

      <div
        className="rounded-[20px] p-4"
        style={{
          background: "linear-gradient(to right, #620F6C, #2C4DCA)",
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span className="text-white">SPF Record</span>
          </div>
          <Status value={data?.SPFRecords} />
        </div>

        <div className="mt-4">
          <p className="text-white text-[0.9rem]">
            {data?.SPFRecords || "SPF Records not found"}
          </p>
        </div>
      </div>

      <div
        className="rounded-[20px] p-4 h-full"
        style={{
          background: "linear-gradient(to right, #620F6C, #2C4DCA)",
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span className="text-white">MX Records</span>
          </div>
          <Status value={data?.MXChanges} />
        </div>

        <div className="mt-4">
          <MXChangesComponent data={data?.MXChanges} />
        </div>
      </div>
    </div>
  );
};

const RightComponent = ({ data }) => {
  return (
    <div
      className="rounded-[20px] py-6 px-8 w-full  h-[28.5rem] overflow-auto"
      style={{
        background: "linear-gradient(to right, #620F6C, #2C4DCA)",
      }}
    >
      <div className="flex items-center gap-2">
        <CheckIcon />
        <span className="text-white">BlackList</span>
      </div>
      {data.length ? (
        <div>
          <div className="flex mt-4">
            <div className="relative w-[60%] px-2 py-1">
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  pointerEvents: "none",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                }}
              />
              <div
                style={{ position: "relative", zIndex: 1 }}
                className="text-white"
              >
                Blacklist Name
              </div>
            </div>
            <div className="relative w-[40%] px-2 py-1">
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  pointerEvents: "none",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                }}
              />
              <div
                style={{ position: "relative", zIndex: 1 }}
                className="text-white"
              >
                Result
              </div>
            </div>
          </div>
          <div className="flx flex-col">
            {data.map((item) => (
              <div key={item.id}>
                <BlacklistItem name={item.name} status={item.detected} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-white mt-3">Not Available</div>
      )}
    </div>
  );
};

const Status = ({ value }) => {
  if (value.length) {
    return (
      <div className="flex gap-2 bg-[#38C793] items-center rounded-md px-2 py-1">
        <ValidIcon /> <span className="text-white text-[0.8rem]">All Good</span>
      </div>
    );
  } else {
    return (
      <div className="flex gap-2 bg-[#DF1C41] items-center rounded-md px-2 py-1">
        <ValidIcon />
        <span className="text-white text-[0.8rem]">Not Valid</span>
      </div>
    );
  }
};

const BlacklistItem = ({ name, status }) => {
  return (
    <div className="flex">
      <div className="relative w-[60%] px-2 py-1">
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.01)",
            pointerEvents: "none",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
            color: "rgba(255, 255, 255, 0.8)",
            paddingBlock: "0.5rem",
          }}
        >
          {name}
        </div>
      </div>
      <div className="relative w-[40%] px-2 py-1">
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.01)",
            pointerEvents: "none",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
            color: "rgba(255, 255, 255, 0.8)",
            paddingLeft: "0.5rem",
            paddingBlock: "0.5rem",
          }}
        >
          <StatusBlacklist value={status} />
        </div>
      </div>
    </div>
  );
};

const StatusBlacklist = ({ value }) => {
  if (!value) {
    return (
      <div className="flex gap-2 bg-[#38C793] items-center rounded-md px-2 py-1 max-w-fit">
        <ValidIcon /> <span className="text-white text-[0.8rem]">All Good</span>
      </div>
    );
  } else {
    return (
      <div className="flex gap-2 bg-[#DF1C41] items-center rounded-md px-2 py-1">
        <ValidIcon />
        <span className="text-white text-[0.8rem]">Not Valid</span>
      </div>
    );
  }
};

function getParameterByName(name, url) {
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const MXChangesComponent = ({ data }) => {
  if (!data.length) {
    return <div className="text-white">MX Records were not found</div>;
  } else {
    return (
      <div>
        {data.map((item) => (
          <p key={item?.exchange} className="text-white text-[0.9rem]">
            {item?.exchange}
          </p>
        ))}
      </div>
    );
  }
};
