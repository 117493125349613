import React from "react";
import {
  NumailBackground,
  NumailBackgroundBottom,
} from "../../../components/icons";

export const BackgroundStyling = () => {
  return (
    <div>
      <div
        className="absolute right-[28rem] top-[2rem] w-[40rem] aspect-square rounded-full z-[-1]"
        style={{
          transform: "rotate(80deg)",
          backgroundColor: "#020F2C",
          filter: "blur(100px)",
        }}
      />
      <div
        className="absolute top-[-35rem] right-[-5rem] w-[60rem] aspect-square rounded-[60px] z-[-1]"
        style={{
          transform: "rotate(80deg)",
          background: "linear-gradient(to bottom left, #02102D, black)",
        }}
      />
      <div
        className="absolute top-[-30rem] right-[-8rem] w-[40rem] aspect-square rounded-[60px] z-[-1]"
        style={{
          transform: "rotate(80deg)",
          background: "linear-gradient(to top right, #02102D, #010101)",
        }}
      />
      <div className="absolute bottom-[-1rem] left-[0rem] z-[-1]">
        <NumailBackground />
      </div>
      <div className=" fixed bottom-[1rem] right-[1rem] z-50 bg-white px-3 py-2 rounded-[0.8rem] flex gap-2 items-center">
        <NumailBackgroundBottom />
        <p className=" text-black text-[0.8rem]">Powered By Numail</p>
      </div>
    </div>
  );
};
